import React from "react";
import {Button} from "../components/catalyst/button";
import {ArrowUturnLeftIcon} from "@heroicons/react/16/solid";
import {useLocation, useNavigate} from "react-router-dom";

export default function Support() {
    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };



    return (
        <>


            <div
                className={"grid h-full w-full md:max-w-lg mx-auto shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"}>

                <h1 className="text-2xl font-bold mb-4 text-center">Kontaktmöglichkeiten:</h1>
                <p className="text-gray-700 text-center">E-Mail: support@juhuu.app</p>
                <p className="text-gray-700 text-center">Telefon: +43 660 9919 009</p>
                <Button  outline onClick={() => handleClick("/auth")}
                        className="mt-10">
                    <ArrowUturnLeftIcon/> Zurück


                </Button>
            </div>

        </>
    );
}
