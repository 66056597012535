import {SubmitHandler, useForm} from "react-hook-form";
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {Button} from "../../../components/catalyst/button";
import {ErrorMessage, Field, Label} from "../../../components/catalyst/fieldset";
import {Input} from "../../../components/catalyst/input";

export default function ResetPassword({intentToken, intentId}:{
    intentId: string,
    intentToken: string
}) {
    const {
        register,
        handleSubmit,

        watch,
        formState: { errors, isSubmitting },
    } = useForm<{ passwordRepeat: string,
        password:string,}>({
        defaultValues:{
            passwordRepeat: "",
            password:"",
        }
    })

    const [disableForm, setDisableForm] = useState(false);

    const [msg, setMsg] = useState<{ msg: string, err: boolean } | null>(null);


    useEffect(()=>{
        let active = true;

        const FetchValid = async () => {
            const result = await axios.get(process.env.REACT_APP_JUHUU_API_ENDPOINT + `/v1/intents/${intentId}/tokenAuth?token=${intentToken}`, {

                validateStatus: () => true,


            }, )

            if(result.data?.intent && active){
                const expiryDate = new Date(result.data?.intent?.invalidAt || "");

                if((new Date().getTime()-expiryDate.getTime()) > 0){
                    setDisableForm(true)
                    setMsg({
                        msg: "Der Link ist abgelaufen, bitte beantrage einen neunen. Du wirst in 5 Sekunden auf die Login Seite weitergeleitet",
                        err: true
                    })

                    setTimeout(()=>{
                        window.location.href = "/auth"
                    },5000)

                }

                if(result.data.intent.status !== "waitingForAction"){
                    setDisableForm(true)
                    setMsg({
                        msg: "Der Link wurde bereits benutzt, bitte beantrage einen neunen. Du wirst in 5 Sekunden auf die Login Seite weitergeleitet",
                        err: true
                    })
                    setTimeout(()=>{
                        window.location.href = "/auth"
                    },5000)
                }

                //console.log()
            }



        }

        FetchValid();


        return ()=> {
            active = false;
        }

    },[intentId, intentToken]);






    const onSubmit: SubmitHandler<{passwordRepeat: string,
        password:string,}> = async (data) =>
    {

        try {

            setDisableForm(true);

            const result = await axios.patch(process.env.REACT_APP_JUHUU_API_ENDPOINT + "/v1/auth/emailPassword/resetPassword", {
                intentId: intentId,
                intentToken: intentToken,
                password: data.password
            },{
                validateStatus: ()=>true,
            })

            if( result.status === 200 || result.status === 201){
                setMsg({msg:"Passwort wurde erfolgreich geändert, du wirst in 3 Sekunden auf die Login Seite weitergeleitet", err:false})

                setTimeout(()=>{
                    window.location.href = "/auth"
                },3000)
            } else {
                setDisableForm(false);
                if(result.data?.message){
                    setMsg({msg:result.data?.message, err:true})
                } else {
                    setMsg({msg:"Es hat nicht geklappt", err:true})
                }


            }

           /* const result = await axios.post(process.env.REACT_APP_JUHUU_API_ENDPOINT + "/v1/auth/emailPassword/resetPassword", {
                email: data.email
            },{
                validateStatus: ()=>true,
            })

            if( result.status === 200 || result.status === 201){
                setMsg({msg:"Es wurde eine E-Mail mit einem Link zum Zurücksetzen des Passworts verschickt", err:false})
            } else {
                if(result.data?.message){
                    setMsg({msg:result.data?.message, err:true})
                } else {
                    setMsg({msg:"Es hat nicht geklappt", err:true})
                }


            }
*/

        } catch (e) {
            setDisableForm(false);
            //console.log(e)
            setMsg({msg:"Etwas ist schief gegangen bitte probiere es erneut!", err:true})
        }

    }

    //const location = useLocation();



    //const navigate = useNavigate();
    /*const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };*/

    const password = watch("password")

    const [PasswordKriterien,AllValid, ValidateObject] = useMemo(() => {


        const List = [
            {
                txt: "Das Passwort muss mind. 8 Zeichen haben (max. 64)",
                valid: (pw: string) => {
                    return pw.length >= 8 && pw.length <= 64
                }
            },
            {
                txt: "Groß- und Kleinbuchstaben erforderlich",
                valid: (pw: string) => {
                    return /(?=.*[a-z])(?=.*[A-Z])/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            },
            {
                txt: "Zahl erforderlich",
                valid: (pw: string) => {
                    return /(?=.*\d)/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            },
            {
                txt: "Sonderzeichen erforderlich",
                valid: (pw: string) => {
                    return /(?=.*\W)/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            }
        ];

        return [List.map((el) => {
            return {
                txt: el.txt,
                valid: el.valid(password || "")
            }
        }),!List.reduce((prev, cur) => {
            return prev && cur.valid(password || "");

        }, true), {
            laenge: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[0].valid(pw) || List[0].txt;
            },
            grossklein: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[1].valid(pw) || List[1].txt;
            },
            zahl: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[2].valid(pw) || List[2].txt;
            },
            sonderzeichen: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[3].valid(pw) || List[3].txt;
            }
        }]

    }, [password])


    return <div
        className={"grid h-full w-full md:max-w-lg mx-auto shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {msg ? (<div
                data-variant={msg.err ? "error" : "success"}
                className="flex items-center p-4 mb-4 text-sm  border rounded-lg data-[variant=success]:text-green-800 data-[variant=success]:border-green-300 data-[variant=success]:bg-green-50  data-[variant=error]:text-red-800 data-[variant=error]:border-red-300 data-[variant=error]:bg-red-50 "
                role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                    {msg.msg}
                </div>
            </div>) : null}

            <div>
                <b className={"text-3xl text-left w-full block mb-3"}>Passwort ändern</b>

            </div>



            <Field>
                <Label>Neues Passwort<sup className={"text-red-600"}>*</sup></Label>
                <Input type="password"
                       {...register("password", {
                           required: true,
                           validate: ValidateObject
                       })}
                       disabled={disableForm || isSubmitting}
                       placeholder={""}/>
                {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}

            </Field>
            <ul className="divide-y divide-gray-100">
                {AllValid && PasswordKriterien.map((pk, index) => (
                    <li key={index} className="flex justify-between gap-x-1 py-1">
                        <div className="flex min-w-0 gap-x-4">
                            {pk.valid ? "✅" : "❌"}
                            <div className="min-w-0 flex-auto">
                                <p className="text-sm font-semibold leading-6 text-gray-900">{pk.txt}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <Field>
                <Label>Passwort wiederholen<sup className={"text-red-600"}>*</sup></Label>
                <Input type="password"
                       {...register("passwordRepeat", {
                           required: true,
                           validate: (value, formValues) => {
                               return value === formValues.password || "Die Passwörter stimmen nicht überein!"
                           }
                       })}
                       disabled={disableForm || isSubmitting}
                       placeholder={""}/>
                {errors.passwordRepeat && <ErrorMessage>{errors.passwordRepeat.message}</ErrorMessage>}

            </Field>

            <div>
                <Button
                    type="submit"
                    disabled={disableForm || isSubmitting}
                    className={"w-full"}>
                    Passwort ändern
                </Button>
            </div>


        </form>
    </div>
}
