import AuthContext from "../AuthProvider";

export type ChangeLoginEmail = {
    email: string,
    updateBillingEmail: boolean
}
/**
 * Change the login email
 * @param data
 */
export default function changeLoginEmail(data: ChangeLoginEmail) {


    return AuthContext.apiRequest(
        `/v1/auth/emailPassword/changeEmail`,
        "PATCH",
        data,
        "Fehler beim Ändern der Email Adresse.",
        true)

}
