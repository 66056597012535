import AuthContext from "../AuthProvider";

/**
 * Delete the user account
 */
export default function deleteUserAccount() {

    return AuthContext.apiRequest(
        `/v1/users/${AuthContext.sub}`,
        "DELETE",
        {},
        "Fehler beim Löschen des Accounts.",
        true)

}
