import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, LoaderFunctionArgs, RouterProvider,} from "react-router-dom";

import RootLayout from "./components/RootLayout";
import Error_404 from "./routes/error/404";
import ErrorPage from "./routes/error";
import AuthIndex from "./routes/auth";
import authProvider from "./AuthProvider";
import WeiterleitenPage from "./routes/weiterleiten";
import SettingsPage from "./routes/settings";
import {CallbackLoginAction} from "./loader/CallbackLoginAction";
import IntentLoader from "./loader/IntentLoader";
import ErrorIntentPage from "./routes/intents/error";
import AbgelaufenPage from "./routes/intents/abgelaufen";
import EmailconfirmedPage from "./routes/intents/accept/emailconfirmed";
import ManagementUserAcceptedPage from "./routes/intents/accept/managementUser";
import EmailRejectedPage from "./routes/intents/reject/emailconfirmed";
import ManagementUserRejectedPage from "./routes/intents/reject/managementUser";

import ReesetPasswordRejectedPage from "./routes/intents/reject/resetPassword";
import ForgotPassword from "./routes/auth/resetPassword/index";
import Support from "./routes/support";
import {checkAndSaveRefURLFromParams, retrieveRefURL} from "./loader/RefLoaderAction";
import RootLayout2 from "./components/RootLayout/index2";


const router = createBrowserRouter([
    {
        id: "intents",
        path: "intents",
        //loader({request}: LoaderFunctionArgs) {},
        Component: RootLayout2,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "abgelaufen",
                Component: AbgelaufenPage,
                errorElement: <ErrorPage />,
            },
            {
                path: "error",
                Component: ErrorIntentPage,
                errorElement: <ErrorPage />,
            },
            {
                path: ":intentId/accept",
                // Component: IntentAcceptPage,
                errorElement: <ErrorPage />,
                loader: async ({ request, params }) => {
                    return IntentLoader(request, params, "accept")
                },
            },
            {
                path: "accept/email-confirmed",
                Component: EmailconfirmedPage,
                errorElement: <ErrorPage />,
            },
            {
                path: "accept/managementUser",
                Component: ManagementUserAcceptedPage,
                errorElement: <ErrorPage />,
            },
            {
                path: "reject/email-confirmed",
                Component: EmailRejectedPage,
                errorElement: <ErrorPage />,
            },
            {
                path: "reject/resetPassword",
                Component: ReesetPasswordRejectedPage,
                errorElement: <ErrorPage />,
            },
            {
                path: "reject/managementUser",
                Component: ManagementUserRejectedPage,
                errorElement: <ErrorPage />,
            },
            {
                path: ":intentId/reject",
                //  Component: IntentRejectPage,
                loader: async ({ request, params }) => {
                    return IntentLoader(request, params, "reject")
                },
                errorElement: <ErrorPage />,
            },
        ],
    },
    {
        path: "callback",
        loader: CallbackLoginAction,
        errorElement: <ErrorPage />,
    },
    {
        id: "root",
        path: "/",
        loader({request}: LoaderFunctionArgs) {

            checkAndSaveRefURLFromParams(request);

            const refURL = retrieveRefURL() || "";

            // Our root route always provides the user, if logged in
            return { refURL: refURL, user:authProvider.userDoc,  accessToken:authProvider.accessToken, refreshToken: authProvider.refreshToken };
        },
        Component: RootLayout,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                Component: AuthIndex
            },
            {
                path: "support",
                Component: Support,
                errorElement: <ErrorPage />,
            },
            {
                path: "auth",
                Component: AuthIndex
            },
            {
                path:"reset-password",
                Component: ForgotPassword
            },
            {
                path: "settings",
                Component: SettingsPage
            },
            {
                path: "weiterleiten",
                Component: WeiterleitenPage
            },
            {
                id: "404",
                path: "*",
                Component: Error_404,
            }
        ]
    },

    ])


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);


