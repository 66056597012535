import {LoaderFunctionArgs, redirect} from "react-router-dom";
import authProvider from "../../AuthProvider";
import {checkAndSaveRefURLFromParams} from "../RefLoaderAction";

export async function CallbackLoginAction({request}: LoaderFunctionArgs) {

    checkAndSaveRefURLFromParams(request);
    const url = new URL(request.url);

    const params = new URLSearchParams(url.searchParams);

    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");


    params.delete("accessToken");
    params.delete("refreshToken");


    if (!accessToken || !refreshToken) {

        return redirect("/auth?" + params.toString());
    }


    await authProvider.signin(accessToken, refreshToken);


    return redirect("/auth?" + params.toString());
}
