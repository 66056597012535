import authProvider from "../../AuthProvider";
import AuthLayoutPage from "../../components/AuthLayout";
import {useMemo} from "react";
import AuthIndexPage from "../../components/AuthIndex";


export default function AuthIndex() {

    const Auth = authProvider.isAuthenticated;


    const isAutheticated = useMemo(() => Auth, [Auth]);

    if (!isAutheticated) {

        return <AuthLayoutPage />

    } else {
        return <AuthIndexPage />
    }

}
