import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";

import {useRevalidator, useRouteLoaderData} from "react-router-dom";
import {RootLoaderType} from "../../types";
import updateUserAccount, {ChangeBillingEmail} from "../../actions/updateUserAccount";
import authProvider from "../../AuthProvider";
import {Button} from "../catalyst/button";
import {ErrorMessage, Field, Label} from "../catalyst/fieldset";
import {Input} from "../catalyst/input";


export default function ChangeBillingEMail() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ChangeBillingEmail["data"]>()



    const {user} = useRouteLoaderData("root") as RootLoaderType;

    const [msg, setMsg] = useState<{ msg: string, err: boolean } | null>(null);


    const revalidator = useRevalidator();

    const onSubmit: SubmitHandler<ChangeBillingEmail["data"]> = async (data) =>
    {

        //console.log(data)

        const result = await updateUserAccount({
            type: "changeBillingEmail",
            data: data
        })

        if(typeof result === 'boolean'){
            if(result){
                //console.log("Rechnungs E-Mail wurde geändert")
                setMsg({msg:"Rechnungs E-Mail wurde geändert", err:false})
                await authProvider.getUserDoc();
                revalidator.revalidate();
            } else {
                setMsg({msg:"Es ist ein Fehler aufgetreten!", err:true})
            }
        } else {
            setMsg({msg:result.err, err:true})
        }

    }

    return <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3" >
        {msg ? (<div
            data-variant={msg.err ? "error" : "success"}
            className="flex items-center p-4 mb-4 text-sm  border rounded-lg data-[variant=success]:text-green-800 data-[variant=success]:border-green-300 data-[variant=success]:bg-green-50  data-[variant=error]:text-red-800 data-[variant=error]:border-red-300 data-[variant=error]:bg-red-50 "
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                {msg.msg}
            </div>
        </div>) : null}


        <Field>
            <Label>Rechnungs E-Mail<sup className={"text-red-600"}>*</sup></Label>
            <Input  type="email"
                    {...register("billingEmail", {required: "Es muss ein Rechnungs E-Mail angegeben werden!"})}
                      placeholder={user?.billingEmail || ""}/>
            {errors.billingEmail && <ErrorMessage>{errors.billingEmail.message}</ErrorMessage>}
        </Field>

        <Button type={"submit"} color={"emerald"}
        >  Speichern
        </Button>
    </form>
}
