import AuthContext from "../AuthProvider";
import {CountryCode} from "../types";


export type UpgradeUserToManagementUser = {
    name: string,
    vat: string,
    billingAddress: {
        line1: string,
        line2: string | null,
        postalCode: string,
        country: CountryCode,
        city: string
    };
    contactPerson: {
        name: string;
        email: string;
        phone: string;
    },
}
/**
 * Upgrade the user to a management user
 * @param data
 */
export default function upgradeUserToManagementUser(data: UpgradeUserToManagementUser) {


    return AuthContext.apiRequest(
        `/v1/users/${AuthContext.sub}/toManagement`,
        "PATCH",
        data,
        "Es ist ein Fehler beim Upgrade auf ein Management Konto aufgetreten.",
        true)

}
