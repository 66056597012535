import React, {useCallback, useEffect, useRef, useState} from "react";
import {useRevalidator} from "react-router-dom";
import authProvider from "../../AuthProvider";
import verifyEmailAdress from "../../actions/verifyEmailAdress";
import {Button} from "../catalyst/button";
import {Text} from "../catalyst/text";

interface VerifyEmailProps {
    needsVerification: boolean;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ needsVerification }) => {
    const [timer, setTimer] = useState<number | null>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

    const revalidator = useRevalidator();
    useEffect(() => {
        if (!needsVerification) return;

        const savedLastAttempt =   sessionStorage.getItem("lastAttempt");
        if (savedLastAttempt) {
            const timeElapsed = Date.now() - parseInt(savedLastAttempt, 10);
            if (timeElapsed < 90000) {
                setTimer(90 - Math.floor(timeElapsed / 1000));
                setIsButtonDisabled(true);
            }
        }
    }, [needsVerification]);
    const handleRefresh = useCallback(async () => {
        await authProvider.getUserDoc();
        revalidator.revalidate();
    },[revalidator]) ;

    const intervalIdRef = useRef<number | null>(null);

    useEffect(() => {
        const verifyFunction = () => {
            //console.log('Verification function called');
            handleRefresh();
            // Place your verification logic here
        };

        if (needsVerification) {
            intervalIdRef.current = window.setInterval(verifyFunction, 1000);
        }

        return () => {
            if (intervalIdRef.current !== null) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }
        };
    }, [needsVerification, handleRefresh]);

    useEffect(() => {
        let timerInterval: string | number | NodeJS.Timeout | undefined;
        if (isButtonDisabled && timer && timer > 0) {
            timerInterval = setInterval(() => {
                setTimer((prevTimer) => prevTimer ? prevTimer - 1 : 0);
            }, 1000);
        } else if (timer === 0) {
            setIsButtonDisabled(false);
            clearInterval(timerInterval);
        }
        return () => clearInterval(timerInterval);
    }, [timer, isButtonDisabled]);

    const handleSendVerification = async () => {
        try {
            //console.log("send verify email")
            const resp = await verifyEmailAdress()

            console.log("Verification Mail:"+resp);
            const currentTime = Date.now();
            sessionStorage.setItem("lastAttempt", currentTime.toString());

            setTimer(90);
            setIsButtonDisabled(true);
        } catch (error) {
            console.error("Error sending verification email", error);
        }
    };



    if (!needsVerification) {
        return null;
    }

    return (
        <div className={"w-full mx-auto max-w-md"}>
            <div
                className=" flex-wrap flex flex-col gap-3 items-center p-4 mb-4 text-sm border rounded-lg text-yellow-800 border-yellow-300 bg-yellow-50"
                role="alert"
            >


                <Text className={"text-[1rem]  "}>
                    Deine E-Mail-Adresse ist noch nicht bestätigt. Klicke hier, um eine neue Bestätigungs-E-Mail zu
                    erhalten.
                </Text>

                <div className="flex flex-wrap  gap-4 w-full">
                    <Button
                        color={"amber"}
                        className={`w-full`}
                        onClick={handleSendVerification}
                        disabled={isButtonDisabled}
                    >
                        {isButtonDisabled ? `Warte ${timer} Sekunden` : "Bestätigungs-E-Mail senden"}
                    </Button>
                    <Button outline className="w-full" onClick={handleRefresh}>
                        Seite aktualisieren
                    </Button>
                </div>


            </div>

        </div>
    );
};

export default VerifyEmail;
