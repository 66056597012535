import {useNavigate} from "react-router-dom";
import {ArrowUturnLeftIcon} from "@heroicons/react/16/solid";
import {Button} from "../../components/catalyst/button";
import React from "react";


export default function Error_404() {

    const navigate = useNavigate();

return (
    <div
        className={"grid h-full w-full md:max-w-lg mx-auto shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"}>

    <section className="bg-white  ">
        <div className="container flex items-center   px-6   mx-auto">
            <div className="flex flex-col items-center max-w-sm mx-auto text-center">
                <p className="p-3 text-sm font-medium text-blue-500 rounded-full bg-blue-50 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                         stroke="currentColor" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                    </svg>
                </p>
                <h1 className="mt-3 text-2xl font-semibold text-gray-800   md:text-3xl">Seite existiert nicht</h1>
                <p className="mt-4 text-gray-500 ">Die Seite, die du suchst, existiert nicht.</p>

                <div className="flex items-center w-full mt-6 gap-x-3 shrink-0 sm:w-auto">
                    <Button  outline  onClick={()=>navigate("/")}
                             className="w-full">
                        <ArrowUturnLeftIcon/> Zurück


                    </Button>



                </div>
            </div>
        </div>
    </section>
    </div>

)
}
