import AuthContext from "../AuthProvider";
import {CountryCode} from "../types";


export interface ChangeVatNumber {
    type: "changeVatNumber";
    data: {
        vat: string;
    }
}

export interface ChangeUserName {
    type: "changeUserName";
    data: {
        name: string;
    }
}

export interface ChangeBillingEmail {
    type: "changeBillingEmail";
    data: {
        billingEmail: string;
    }
}

  interface Adress {
    line1: string,
    line2: string | null,
    postalCode: string,
    country: CountryCode,
    city: string,
}

export interface ChangeBillingAddress {
    type: "changeBillingAddress";
    data: Adress;
}

export type UpdateUserAccount = ChangeVatNumber | ChangeUserName | ChangeBillingEmail | ChangeBillingAddress;
/**
 * Update the user account
 * @param props
 */
export default function updateUserAccount(props: UpdateUserAccount) {

    let error = "";
    switch (props.type) {
        case "changeBillingAddress":
            error = "Fehler beim Ändern der Rechnungsadresse.";
            break;
        case "changeBillingEmail":
            error = "Fehler beim Ändern der Rechnungs Email Adresse.";
            break;
        case "changeUserName":
            error = "Fehler beim Ändern des Benutzernamens.";
            break;
        case "changeVatNumber":
            error = "Fehler beim Ändern der Umsatzsteuer Identifikationsnummer.";
            break;

    }

    return AuthContext.apiRequest<UpdateUserAccount["data"]>(
        `/v1/users/${AuthContext.sub}`,
        "PATCH",
        props.data,
        error,
        true)

}
