import {LoaderFunctionArgs} from "react-router-dom";

function validateRefUrl(url: any): boolean {
    // Check if the URL is not provided or is not a string
    if (!url || typeof url !== 'string') {
        return false;
    }

    try {
        // Parse the URL
        const parsedUrl = new URL(url);
        const isDevMode = process.env.REACT_APP_STAGING_URL === 'true';

        if(isDevMode){
            return true;
        }
        // Validate the protocol



        if (parsedUrl.protocol === 'juhuu:') {
            // Only allow the domain 'auth' for 'juhuu:' protocol
            //console.log(parsedUrl)
            return parsedUrl.pathname === '//auth';
        } else if (parsedUrl.protocol === 'https:') {
            // Determine if in development mode
            if(parsedUrl.hostname.includes("localhost")){
                return true;
            }

            // Define the allowed domains based on the environment
            const allowedDomains = isDevMode
                ? /\.juhuu\.dev$/  // Allow domains ending with .juhuu.dev in development mode
                : /\.juhuu\.app$/; // Allow domains ending with .juhuu.app in production mode

            // Test if the hostname matches the allowed domains
            return allowedDomains.test(parsedUrl.hostname);
        }

        // If the protocol is neither 'juhuu:' nor 'https:', return false
        return false;
    } catch (e) {
        // If parsing fails, return false
        return false;
    }
}

// Function to save the refURL in both localStorage and cookies
const saveRefURL = (refURL: string) => {
    // Save in localStorage
    localStorage.setItem('validatedRefURL', refURL);

    // Save in cookies with an expiration date (1 year)
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    document.cookie = `validatedRefURL=${refURL}; expires=${expiryDate.toUTCString()}; path=/`;
};



// Function to retrieve the refURL with the following order of priority:
// 1. URL Parameters (validated)
// 2. localStorage (validated)
// 3. Cookies (validated)
const retrieveRefURL = (): string | null => {
    // Check URL parameters first
    const params = new URLSearchParams(window.location.search);
    const paramRefURL = params.get('refURL');
    if (paramRefURL && validateRefUrl(paramRefURL)) {
        saveRefURL(paramRefURL);
        return paramRefURL;
    }

    // Try to retrieve from localStorage
    const localStorageRefURL = localStorage.getItem('validatedRefURL');
    if (localStorageRefURL && validateRefUrl(localStorageRefURL)) {
        return localStorageRefURL;
    }

    // If not found in localStorage, try to retrieve from cookies
    const match = document.cookie.match(new RegExp('(^| )validatedRefURL=([^;]+)'));
    if (match && validateRefUrl(match[2])) {
        return match[2];
    }

    // If not found in any storage, return null
    return null;
};

// Function to parse URL parameters and save refURL if it exists
const checkAndSaveRefURLFromParams = (request: LoaderFunctionArgs["request"]) => {
    const url = new URL(request.url);

    const params = new URLSearchParams(url.searchParams);

    const refURL = params.get("refURL");
    if (refURL && validateRefUrl(refURL)) {
        saveRefURL(refURL);
    }
};

export {
    checkAndSaveRefURLFromParams,
    validateRefUrl,
    saveRefURL,
    retrieveRefURL
}
