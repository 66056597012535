import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useRevalidator, useRouteLoaderData} from "react-router-dom";
import {CountryCodeArray, RootLoaderType} from "../../types";
import upgradeUserToManagementUser, {UpgradeUserToManagementUser} from "../../actions/upgradeUserToManagementUser";
import authProvider from "../../AuthProvider";
import {Button} from "../catalyst/button";
import {ErrorMessage, Field, Label} from "../catalyst/fieldset";
import {Input} from "../catalyst/input";
import {Select} from "../catalyst/select";



export default function UpgradeToManagementUser() {

    const {user} = useRouteLoaderData("root") as RootLoaderType;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpgradeUserToManagementUser>(
        {

        }
    )





    const [msg, setMsg] = useState<{ msg: string, err: boolean } | null>(null);


    const revalidator = useRevalidator();

    const onSubmit: SubmitHandler<UpgradeUserToManagementUser> = async (data) =>
    {

        //console.log(data)



        const result = await upgradeUserToManagementUser(data);

        if(typeof result === 'boolean'){
            if(result){
                //console.log("Upgrade wurde durchgeführt")
                setMsg({msg:"Upgrade wurde durchgeführt", err:false})
                await authProvider.getUserDoc();
                revalidator.revalidate();
            } else {
                setMsg({msg:"Es ist ein Fehler aufgetreten!", err:true})
            }
        } else {
            setMsg({msg:result.err, err:true})
        }

    }

    return <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
        {msg ? (<div
            data-variant={msg.err ? "error" : "success"}
            className="flex items-center p-4 mb-4 text-sm  border rounded-lg data-[variant=success]:text-green-800 data-[variant=success]:border-green-300 data-[variant=success]:bg-green-50  data-[variant=error]:text-red-800 data-[variant=error]:border-red-300 data-[variant=error]:bg-red-50 "
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                {msg.msg}
            </div>
        </div>) : null}



        <Field>
            <Label>Vollständiger Name<sup className={"text-red-600"}>*</sup></Label>
            <Input   type="text"
                     {...register("name", {required: "Es muss ein Name angegeben werden!"})}
                      placeholder={user?.name || ""} />
            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
        </Field>


        <hr/>
        <b>VAT</b>

        <Field>
            <Label>VAT Nummer<sup className={"text-red-600"}>*</sup></Label>
            <Input  type="text"
                    {...register("vat", {required: "Es muss ein VAT Nummer angegeben werden!"})}
                    placeholder={user?.vat || ""} />
            {errors.vat && <ErrorMessage>{errors.vat.message}</ErrorMessage>}
        </Field>
        <hr/>
        <b>Kontaktperson</b>



        <Field>
            <Label>Name<sup className={"text-red-600"}>*</sup></Label>
            <Input   type="text"
                     {...register("contactPerson.name", {required: "Es muss ein Name angegeben werden!"})}
                      placeholder={user?.contactPerson?.name || ""} />
            {errors.contactPerson?.name && <ErrorMessage>{errors.contactPerson?.name.message}</ErrorMessage>}
        </Field>


        <Field>
            <Label>E-Mail<sup className={"text-red-600"}>*</sup></Label>
            <Input   type="email"
                     {...register("contactPerson.email", {required: "Es muss ein E-Mail angegeben werden!"})}
                      placeholder={user?.contactPerson?.email || ""} />
            {errors.contactPerson?.email && <ErrorMessage>{errors.contactPerson?.email.message}</ErrorMessage>}
        </Field>

        <Field>
            <Label>Telefonnummer<sup className={"text-red-600"}>*</sup></Label>
            <Input   type="tel"
                     {...register("contactPerson.phone", {required: "Es muss ein Telefonnummer angegeben werden!"})}
                      placeholder={user?.contactPerson?.phone || ""} />
            {errors.contactPerson?.phone && <ErrorMessage>{errors.contactPerson?.phone.message}</ErrorMessage>}
        </Field>


        <hr/>
        <b>Adresse</b>

        <Field>
            <Label>Adresszeile 1<sup className={"text-red-600"}>*</sup></Label>
            <Input   type="text"
                     {...register("billingAddress.line1", {required: "Es muss eine Straße und Hausnummer eingegeben Werden"})}
                      placeholder={user?.billingAddress?.line1 || "Straße, Hausnummer"} />
            {errors.billingAddress?.line1 && <ErrorMessage>{errors.billingAddress?.line1.message}</ErrorMessage>}
        </Field>


        <Field>
            <Label>Adresszeile 2</Label>
            <Input   type="text"
                     {...register("billingAddress.line2")}
                     placeholder={user?.billingAddress?.line2 || "..."} />
            {errors.billingAddress?.line2 && <ErrorMessage>{errors.billingAddress?.line2.message}</ErrorMessage>}
        </Field>

        <div className="flex gap-3">

            <Field>
                <Label>PLZ<sup className={"text-red-600"}>*</sup></Label>
                <Input   type="text"
                         {...register("billingAddress.postalCode", {required: "Es muss eine Postleitzahl eingegeben Werden"})}
                         placeholder={user?.billingAddress?.postalCode || "PLZ"} />
                {errors.billingAddress?.postalCode && <ErrorMessage>{errors.billingAddress?.postalCode.message}</ErrorMessage>}
            </Field>



            <Field>
                <Label>Stadt<sup className={"text-red-600"}>*</sup></Label>
                <Input   type="text"
                         {...register("billingAddress.city", {required: "Es muss eine Stadt eingegeben Werden"})}
                          placeholder={user?.billingAddress?.city || "Stadt"} />
                {errors.billingAddress?.city && <ErrorMessage>{errors.billingAddress?.city.message}</ErrorMessage>}
            </Field>
        </div>
        <Field>
            <Label>Land<sup className={"text-red-600"}>*</sup></Label>
            <Select  {...register("billingAddress.country", {required: "Es muss ein Land ausgewählt werden"})}>
                <option value={""}>Land auswählen</option>
                {
                    CountryCodeArray.map((countryCode, index) => {

                        return <option key={`country_${index}`} value={countryCode.code}>{countryCode.name}</option>
                    })
                }
            </Select>
            {errors.billingAddress?.country && <ErrorMessage>{errors.billingAddress?.country.message}</ErrorMessage>}
        </Field>




        <Button type={"submit"} color={"amber"}
        >  Upgrade
        </Button>
    </form>
}
