import React, {useMemo} from "react";
import {useSearchParams} from "react-router-dom";
import RequestPassword from "./requestPassword";
import ResetPassword from "./resetPassword";

export default function ForgotPassword() {

    const [search] = useSearchParams();

    const [intentId, intentToken] = useMemo(()=>{
        let intentId = search.get("intentId");
        let intentToken = search.get("intentToken");
        if(intentId === ""){
            intentId = null;
        }
        if(intentToken === ""){
            intentToken = null;
        }
        return [intentId, intentToken];
    },[search])


   if(intentId && intentToken ){
       return  <ResetPassword intentId={intentId} intentToken={intentToken}/>
   } else {
       return <RequestPassword />
   }
}
