import axios from "axios";
import {redirect} from "react-router-dom";

export default async function IntentLoader(request: Request, params: {
    intentId?: string
}, action: "accept" | "reject") {
    const response = await axios.get(process.env.REACT_APP_JUHUU_API_ENDPOINT + `/v1/intents/${params.intentId}/tokenAuth?token=${new URL(request.url).searchParams.get("token")}`, {
        // headers: {"Authorization": `Bearer ${authProvider.accessToken}`}
    });

    const data = response.data?.intent;

    //console.log(data);

    switch (data?.type || "error") {

        case "verifyBillingEmail": {

            ////console.log(data);

            if (data.status !== "waitingForAction") {
                return redirect(`/intents/abgelaufen`);
            }

            const Presponse = await axios.patch(process.env.REACT_APP_JUHUU_API_ENDPOINT + `/v1/intents/${params.intentId}/accept?token=${new URL(request.url).searchParams.get("token")}`, {});

            const Pdata = Presponse.data?.intent;
            if (Pdata.status === "accepted") {
                return redirect(`/intents/accept/email-confirmed`);
            } else if (Pdata.status === "rejected") {
                return redirect(`/intents/reject/email-confirmed`);
            } else {
                return redirect(`/intents/error`);
            }

        }
        case "managementUser": {

            if (data.status !== "waitingForAction") {
                return redirect(`/intents/abgelaufen`);
            }

            const Presponse = await axios.patch(process.env.REACT_APP_JUHUU_API_ENDPOINT + `/v1/intents/${params.intentId}/${action}?token=${new URL(request.url).searchParams.get("token")}`, {});

            const Pdata = Presponse.data?.intent;

            if (Pdata.status === "accepted") {
                return redirect(`/intents/accept/managementUser`);
            } else if (Pdata.status === "rejected") {
                return redirect(`/intents/reject/managementUser`);
            } else {
                return redirect(`/intents/error`);
            }
        }
        case "resetPassword": {
            if (data.status !== "waitingForAction") {
                return redirect(`/intents/abgelaufen`);
            }
            if (action === "reject") {
                const Presponse = await axios.patch(process.env.REACT_APP_JUHUU_API_ENDPOINT + `/v1/intents/${params.intentId}/reject?token=${new URL(request.url).searchParams.get("token")}`, {});
                const Pdata = Presponse.data?.intent;


                if (Pdata.status === "rejected") {
                    return redirect(`/intents/reject/resetPassword`);
                } else {
                    return redirect(`/intents/error`);
                }
            } else {
                return redirect(`/reset-password?intentId=${params.intentId}&intentToken=${new URL(request.url).searchParams.get("token")}`);
            }
        }
        default: {
            return redirect(`/intents/error`);
        }
    }
}
