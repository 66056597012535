import {SubmitHandler, useForm} from "react-hook-form";
import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";

import {Text} from "../../../components/catalyst/text";
import {Button} from "../../../components/catalyst/button";
import {ErrorMessage, Field, Label} from "../../../components/catalyst/fieldset";
import {Input} from "../../../components/catalyst/input";
export default function RequestPassword() {
    const {
        register,
        handleSubmit,


        formState: { errors, isSubmitting },
    } = useForm<{email: string}>()


    const [disableForm, setDisableForm] = useState(false);

    const [msg, setMsg] = useState<{ msg: string, err: boolean } | null>(null);



    const onSubmit: SubmitHandler<{email: string}> = async (data) =>
    {

        try {
            const result = await axios.post(process.env.REACT_APP_JUHUU_API_ENDPOINT + "/v1/auth/emailPassword/resetPassword", {
                email: data.email
            },{
                validateStatus: ()=>true,
            })

            if( result.status === 200 || result.status === 201){
                setMsg({msg:"Es wurde eine E-Mail mit einem Link zum Zurücksetzen des Passworts verschickt, du wirst auf die Login Seite weitergeleitet", err:false})
                setDisableForm(true)

                setTimeout(()=>{
                   window.location.replace("/auth")
                }, 3000)
            } else {
                if(result.data?.message){
                    setMsg({msg:result.data?.message, err:true})
                } else {
                    setMsg({msg:"Es hat nicht geklappt", err:true})
                }


            }


        } catch (e) {
            //console.log(e)
            setMsg({msg:"Etwas ist schief gegangen bitte probiere es erneut!", err:true})
        }

    }

    const location = useLocation();



    const navigate = useNavigate();
    const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };


    return <div
        className={"grid h-full w-full md:max-w-lg mx-auto shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {msg ? (<div
                data-variant={msg.err ? "error" : "success"}
                className="flex items-center p-4 mb-4 text-sm  border rounded-lg data-[variant=success]:text-green-800 data-[variant=success]:border-green-300 data-[variant=success]:bg-green-50  data-[variant=error]:text-red-800 data-[variant=error]:border-red-300 data-[variant=error]:bg-red-50 "
                role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span className="sr-only">Info</span>
                <div>
                    {msg.msg}
                </div>
            </div>) : null}

            <div>
                <b className={"text-3xl text-left w-full block mb-3"}>Passwort zurücksetzen</b>
                <Text>
                    Gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen.
                    Im Anschluss erhältst du eine E-Mail mit einem Link über den du dein Passwort zurücksetzen
                    kannst.
                </Text>
            </div>

            <div>
                <Field>
                    <Label>E-Mail</Label>
                    <Input  {...register("email", {required: "Es muss eine E-Mail Adresse angegeben werden"})}
                            id="email"
                            type="email"

                            autoComplete="email"
                            disabled={disableForm || isSubmitting}
                            required />
                    {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

                </Field>

            </div>

            <div>
                <Button
                    className="w-full"
                    type="submit"
                    disabled={disableForm || isSubmitting}
                   >
                    Passwort zurücksetzen
                </Button>
            </div>
            <div>
                <Button
                    outline
                    className="w-full"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => handleClick("/auth")}
                    >
                    Jetzt anmelden
                </Button>
            </div>

        </form></div>
        }
