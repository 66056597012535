import AuthContext from "../AuthProvider";

/**
 * Export the user account
 */
export default function exportUserAccount() {

    return AuthContext.apiRequest(
        `/v1/users/${AuthContext.sub}/exportData`,
        "POST",
        {},
        "Fehler beim Exportieren des Accounts.",
        true)

}
