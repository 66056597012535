import {SubmitHandler, useForm} from "react-hook-form";
import React, {useMemo, useState} from "react";
import authProvider from "../../../AuthProvider";
import {ErrorMessage, Field, Label} from "../../../components/catalyst/fieldset";
import {Input} from "../../../components/catalyst/input";
import {Button} from "../../../components/catalyst/button";

export default function Register({accepted}:{accepted:boolean}) {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting },
    } = useForm<{email: string, password: string, name: string, password_repeat: string}>({
        defaultValues:{
            password: "",
            password_repeat: "",
            name:"",
            email: ""
        }
    })
    const [errorMsg, setErrorMsg] = useState<null | {err: string}>(null);



    const onSubmit: SubmitHandler<{ email: string, password: string, name: string, password_repeat: string}> = async (data) =>
    {

        try {
            const result = await fetch(process.env.REACT_APP_JUHUU_API_ENDPOINT + "/v1/auth/emailpassword/register", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: data.name,
                    email: data.email,
                    password: data.password,
                })
            })

            const json = await result.json();

            if (json.message === "User already exists") {

                setErrorMsg({
                    err: "Du hast bereits einen Account, versuche dich dort anzumelden"
                });   }

            if (json.accessToken && json.refreshToken) {

                await authProvider.signin(json.accessToken, json.refreshToken);
                window.location.reload();
            } else {
                setErrorMsg({
                    err: "Etwas ist schief gegangen bitte probiere es erneut!"
                });
            }


        } catch (e) {
            setErrorMsg({
                err: "Etwas ist schief gegangen bitte probiere es erneut!",
            }) ;
        }

    }
    const password = watch("password")

    const [PasswordKriterien, ValidateObject] = useMemo(()=>{


        const List = [
            {
                txt: "Das Passwort muss mind. 8 Zeichen haben (max. 64)",
                valid: (pw: string)=>{
                    return pw.length >= 8 && pw.length <= 64
                }
            },
            {
                txt: "Groß- und Kleinbuchstaben erforderlich",
                valid: (pw: string)=>{
                    return /(?=.*[a-z])(?=.*[A-Z])/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            },
            {
                txt: "Zahl erforderlich",
                valid: (pw: string)=>{
                    return /(?=.*\d)/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            },
            {
                txt: "Sonderzeichen erforderlich",
                valid: (pw: string)=>{
                    return /(?=.*\W)/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            }
        ];

        return [List.map((el)=>{
            return {
                txt: el.txt,
                valid: el.valid(password)
            }
        }),{
            laenge: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[0].valid(pw) || List[0].txt;
            },
            grossklein: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[1].valid(pw) || List[1].txt;
            },
            zahl: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[2].valid(pw) || List[2].txt;
            },
            sonderzeichen: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[3].valid(pw) || List[3].txt;
            }
        }]

    },[password])

    return <form onSubmit={handleSubmit(onSubmit)}
                 className={`space-y-2 w-full mt-4 ${accepted ? "" : "pointer-events-none opacity-40"}`}>
        {errorMsg?.err ? (<div
            className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 "
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                {errorMsg.err}
            </div>
        </div>) : null}
        <div>
            <Field>
                <Label>Name</Label>
                <Input {...register("name", {required: true,})}
                       id="name"
                       type="text"
                       autoComplete="name"
                       required
                       disabled={isSubmitting}/>
            </Field>
        </div>
        <div>
            <Field>
                <Label>E-Mail Adresse</Label>
                <Input  {...register("email", {required: true})}
                        id="email"

                        type="email"
                        autoComplete="email"
                        required
                        disabled={isSubmitting}/>
            </Field>

        </div>

        <div>
            <Field>
                <Label>Passwort</Label>
                <Input   {...register("password", {
                    required: true,
                    validate: ValidateObject
                })}
                         id="password"

                         type="password"
                         autoComplete="current-password"
                         required
                         disabled={isSubmitting}
                         invalid={!!errors.password}
                />
                {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
            </Field>

            <div className="mt-2">

                <ul className="divide-y divide-gray-100">
                    {PasswordKriterien.map((pk, index) => (
                        <li key={index} className="flex justify-between gap-x-1 py-1">
                            <div className="flex min-w-0 gap-x-4">
                                {pk.valid ? "✅" : "❌"}
                                <div className="min-w-0 flex-auto">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{pk.txt}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <Field>
                    <Label>Passwort wiederholen</Label>
                    <Input     {...register("password_repeat", {
                        required: true,
                        validate: (value, formValues) => {
                            return value === formValues.password || "Die Passwörter stimmen nicht überein!"
                        }
                    })}
                               id="password_repeat"
                               type="password"
                               autoComplete="off"
                               required
                               disabled={isSubmitting}
                               invalid={!!errors.password_repeat}
                    />
                    {errors.password_repeat && <ErrorMessage>{errors.password_repeat.message}</ErrorMessage>}
                </Field>

            </div>

        </div>

        <div className={"flex justify-start pt-3"}>
            <Button
                className="w-full"
                type="submit"
                disabled={isSubmitting}

            >
                Registrieren
            </Button>
        </div>


    </form>
}
