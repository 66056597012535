import React, {ReactNode} from "react";
import {Divider} from "../catalyst/divider";
import {Subheading} from "../catalyst/heading";
import {Text} from "../catalyst/text";

export default function SettingsPanel({
                                          children, title, disabled = false, text = ""
                                      }: { title: string, children: ReactNode , disabled?: boolean, text ?: string}) {


    return <>
        <Divider className="my-10 mt-6"/>
        <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="space-y-1">
                <Subheading>{title}</Subheading>
                <Text>{text}</Text>
            </div>
            <div>
                {children}
            </div>
        </section>
    </>



}
