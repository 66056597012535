import React from "react";
import {Heading} from "../../../components/catalyst/heading";
import {Text} from "../../../components/catalyst/text";
import {Button} from "../../../components/catalyst/button";

export default function ManagementUserAcceptedPage() {




    return (
        <>

            <Heading  >Management-Account beigetreten</Heading>
            <Text  >Du bist jetzt Mitglied eines Management-Accounts. Produkte, die der Management-Account kauft, können von seinen Mitgliedern verwendet werden.</Text>
            <Button className={"w-full mt-10" } href={"/"} outline >
                zurück zur Startseite
            </Button>


        </>
    );
}
