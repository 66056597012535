
export const CountryCodeArray = [
    {code: "DE", name: "Deutschland"}, // Germany
    {code: "AT", name: "Österreich"}, // Austria
    {code: "CH", name: "Schweiz"}, // Switzerland
    {code: "LI", name: "Liechtenstein"}, // Liechtenstein
    {code: "IT", name: "Italien"}, // Italy
    {code: "FR", name: "Frankreich"}, // France
    {code: "NL", name: "Niederlande"}, // Netherlands
    {code: "BE", name: "Belgien"}, // Belgium
    {code: "LU", name: "Luxemburg"}, // Luxembourg
    {code: "DK", name: "Dänemark"}, // Denmark
    {code: "SE", name: "Schweden"}, // Sweden
    {code: "NO", name: "Norwegen"}, // Norway
    {code: "FI", name: "Finnland"}, // Finland
    {code: "IS", name: "Island"}, // Iceland
    {code: "GB", name: "Großbritannien"}, // Great Britain
    {code: "IE", name: "Irland"}, // Ireland
    {code: "ES", name: "Spanien"}, // Spain
    {code: "PT", name: "Portugal"}, // Portugal
    {code: "GR", name: "Griechenland"}, // Greece
    {code: "PL", name: "Polen"}, // Poland
    {code: "CZ", name: "Tschechien"}, // Czech Republic
    {code: "SK", name: "Slowakei"}, // Slovakia
    {code: "HU", name: "Ungarn"}, // Hungary
    {code: "SI", name: "Slowenien"}, // Slovenia
    {code: "HR", name: "Kroatien"}, // Croatia
    {code: "BA", name: "Bosnien und Herzegowina"}, // Bosnia and Herzegovina
    {code: "RS", name: "Serbien"}, // Serbia
    {code: "US", name: "Vereinigte Staaten"}, // United States
    {code: "CA", name: "Kanada"} // Canada
] as const;

export type CountryCode = (typeof CountryCodeArray)[number]['code'];
export type CountryName = (typeof CountryCodeArray)[number]['name'];
export type RootLoaderType = {
    user: null | {
        billingEmailVerified: boolean,
        name: string | null,
        billingEmail: string | null,
        billingAddress: {
            line1: string,
            line2: string | null,
            postalCode: string,
            country: CountryCode,
            city: string
        } ,
        type: "management" | "standard" | string,
        vat: string | null,
        contactPerson: {
            name: string | null;
            email: string| null;
            phone: string| null;
        } | null
    },
    accessToken: string,
    refreshToken: string,
    refURL: string | null
}

export {}
