import React from "react";
import {Heading} from "../../components/catalyst/heading";
import {Text} from "../../components/catalyst/text";
import {Button} from "../../components/catalyst/button";

export default function AbgelaufenPage() {




    return (
        <>

            <Heading  >Link nicht mehr gültig</Heading>
            <Text  >Dieser Link ist leider nicht mehr gültig oder wurde bereits
                bestätigt.</Text>
            <Button className={"w-full mt-10" } href={"/"} outline >
                zurück zur Startseite
            </Button>

        </>
    );
}
