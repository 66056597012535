
import {jwtDecode} from "jwt-decode";
import axios from "axios";
import {RootLoaderType} from "../types";


class AuthProvider{

    isAuthenticated: boolean;
    sub: string | null;
    accessToken: null | string;
    refreshToken: null | string;
    userDoc: RootLoaderType["user"];

    constructor() {

        this.isAuthenticated = false;
        this.sub = null;
        this.accessToken = null;
        this.refreshToken = null;
        this.userDoc = null;

        this.loadFromStorage();
        this.setupInactivityTimer();
    }

    /**
     * Initializes the AuthProvider with the given tokens
     */
    loadFromStorage() {
        const storedAuth = localStorage.getItem("auth");
        const storedUserDoc = localStorage.getItem("userDoc");

        if (storedAuth) {
            const {accessToken, refreshToken} = JSON.parse(storedAuth);
            this.accessToken = accessToken;
            this.refreshToken = refreshToken;

            if(this.isAccessTokenValid()){
                this.isAuthenticated = true;
            }

            if (storedUserDoc) {
                this.userDoc = JSON.parse(storedUserDoc);
            } else {
                this.getUserDoc();
            }
        }

    }

    /**
     * Fetches the user document from the server i User is Inaktive for more then 1 minute
     */
    setupInactivityTimer() {
        let timeoutId: string | number | NodeJS.Timeout | undefined;
        const resetTimer = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(async () => {
                if (this.isAuthenticated) {
                    await this.getUserDoc();
                }
            }, 1 * 60 * 1000); // 10 minutes of inactivity
        };

        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer);
        window.addEventListener("scroll", resetTimer);
        window.addEventListener("click", resetTimer);

        document.addEventListener("visibilitychange", async () => {
            if (document.visibilityState === "visible" && this.isAuthenticated) {
                await this.getUserDoc();
            }
        });

        resetTimer(); // Initialize the timer
    }

    /**
     * Check if current Access Token is valid
     * @returns {boolean}
     */
    isAccessTokenValid(): boolean {
        if (!this.accessToken) return false;
        try {
            const decodedToken: { exp: number, sub: string } = jwtDecode(this.accessToken);
            this.sub = decodedToken.sub;
            const currentTime = Math.floor(Date.now() / 1000);
            return decodedToken.exp > currentTime;
        } catch (error) {
            console.error("Error decoding token:", error);
            return false;
        }
    }

    /**
     * Get a new Refresh Token from the server
     */
    async getNewRefreshToken(): Promise<boolean>{
        const response = await axios.get(process.env.REACT_APP_JUHUU_API_ENDPOINT + "/v1/auth/refresh", {
            headers: {"Authorization": `Bearer ${this.refreshToken}`}
        });


        if (response.status === 200) {
            const {accessToken, refreshToken} = response.data;
            await this.signin(accessToken, refreshToken);
            return true;
        } else {
            alert("Dein Login ist abgelaufen und du wirst abgemeldet. Bitte melde dich wieder erneut an.")
            await this.signout();
            return false;
        }
    }

    /**
     * Verify that the user has a valid Access Token and Refresh Token
     */
    async verifyAuthenticationStatus(): Promise<boolean> {
        if (!this.isAccessTokenValid()) {
            try {
                return await this.getNewRefreshToken();
            } catch (error) {
                console.error("Error refreshing token:", error);
                return false;
            }
        }
        return true;
    }

    /**
     * Get the current Signed in User
     */
    async getUserDoc(): Promise<RootLoaderType["user"]> {
        if (!await this.verifyAuthenticationStatus()) return null;
        try {
            const response = await axios.get(process.env.REACT_APP_JUHUU_API_ENDPOINT + `/v1/users/${this.sub}`, {
                headers: {"Authorization": `Bearer ${this.accessToken}`}
            });
            if (response.status === 200 && response?.data?.user) {
                const user = response.data.user;
                this.userDoc = {
                    billingEmail: user.billingEmail,
                    name: user.name,
                    billingAddress: user.billingAddress,
                    type: user.type,
                    vat: user.vat,
                    contactPerson: user.contactPerson,
                    billingEmailVerified: !!user.billingEmailVerified,
                }
                localStorage.setItem("userDoc", JSON.stringify(this.userDoc));
            } else {
                console.error("Failed to fetch user document");
            }
        } catch (error) {
            console.error("Error fetching user document:", error);
        }
        return null;
    }

    /**
     * Sign in the user with the given tokens
     * @param accessToken {string}
     * @param refreshToken {string}
     */
    async signin(accessToken: string, refreshToken: string): Promise<void> {

        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        localStorage.setItem("auth", JSON.stringify({accessToken, refreshToken}));

        this.isAuthenticated = this.isAccessTokenValid();

        await this.getUserDoc();
    }

    /**
     * Sign out the user and delete all tokens
     */
    async signout() {
        //console.log("Signing out...");
        this.isAuthenticated = false;
        this.sub = null;
        this.accessToken = null;
        this.refreshToken = null;
        this.userDoc = null;
        localStorage.removeItem("auth");
        localStorage.removeItem("userDoc");
        //console.log("Cleared local storage and updated state.");
        window.location.replace("/auth");
    }


    /**
     * Request to the API
     * @param url
     * @param method
     * @param data
     * @param errorMsg
     * @param needsAuth
     */
    async apiRequest<Data = {}>(url: string, method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE", data: Data,errorMsg = "", needsAuth = true):Promise<boolean | {err: string}>{

        try {

            if(needsAuth && !await this.verifyAuthenticationStatus()){
                return {
                    err: "Du bist nicht eingeloggt. Bitte logge dich erneut ein."
                };
            }

            const response = await axios<Data>({
                url: process.env.REACT_APP_JUHUU_API_ENDPOINT + url,
                method: method,
                headers: {
                    "Authorization": `Bearer ${this.accessToken}`
                },
                validateStatus: () => true,
                data: data
            })



            if (response.status === 200 || response.status === 201) {

                return true;
            } else {


                // @ts-ignore
                if(response.data?.message){
                    return {
                        // @ts-ignore
                        err: response.data.message
                    }
                }
                return {
                    err: errorMsg
                }
            }

        } catch (error) {
            console.error(error)
            return {
                err: "Es ist ein Fehler aufgetreten"
            }
        }

    }


}


const AuthContext = new AuthProvider();

export default AuthContext;
