import React, {useMemo, useState} from "react";
import {useLocation, useNavigate, useRouteLoaderData, useSearchParams} from "react-router-dom";

import VerifyEmail from "../../components/verifyEmail";
import VerifySettings from "../../components/verifySettings";
import authProvider from "../../AuthProvider";
import {RootLoaderType} from "../../types";
import {Heading} from "../catalyst/heading";
import {Button} from "../catalyst/button";
import {Text} from "../catalyst/text";
import {ArrowLeftStartOnRectangleIcon} from '@heroicons/react/16/solid'

export default function AuthIndexPage() {

    const { user, refURL } = useRouteLoaderData("root") as RootLoaderType;

    let [searchParams] = useSearchParams();

    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };


    const [RefUrl, CallbackUrl]  = useMemo(()=>{

      //  const refUrlRaw = searchParams.get("refURL");
        const callbackURL = searchParams.get("callbackURL");
        if(!refURL){
            return ["juhuu://auth", callbackURL];
        }




        return [refURL , callbackURL];

    },[searchParams, refURL])

    const isAllowedToLogin = useMemo(()=>{

        return !!user?.name && !!user?.billingEmail && user?.billingEmailVerified;

    },[user])

    const [rageClickDetection, setRageClickDetection] = useState(0);

    return (
        <div className={"grid h-full mx-auto w-full md:max-w-md shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"} >
            <VerifySettings/>
            <VerifyEmail needsVerification={((!user?.billingEmailVerified) && !!user?.billingEmail)}/>
            {rageClickDetection > 5 ? <div
                className="mx-4 f flex   gap-3 items-center p-4 mb-4 text-xs border rounded-lg text-red-800 border-red-300 bg-red-50"
                role="alert"
            >
                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>

                <div className={"text-[1rem]  "}>
                    Es sieht so aus, als hättest du die JUHUU App noch nicht installiert. Lade dir die App herunter und
                    probiere es dann erneut.
                </div>


            </div> : null}

            <div className="sm:mx-auto sm:w-full  flex gap-5 flex-col">

                <Heading className={"text-xl text-center"}>Angemeldet als {user?.name}</Heading>
                <div className={"mb-10"} style={{display: RefUrl ? "flex" : "none", flexDirection: "column"}}>
                    <Button color={"violet"} onClick={() => {

                        if (!isAllowedToLogin) {
                            return;
                        }

                        const params = new URLSearchParams();

                        params.set("refreshToken", authProvider.refreshToken || "");
                        params.set("accessToken", authProvider.accessToken || "");
                        if (CallbackUrl) {
                            params.set("callbackURL", CallbackUrl);
                        }


                        window.location.replace(RefUrl + "?" + params.toString());
                        if(RefUrl && RefUrl.startsWith("juhuu://")) {
                            setRageClickDetection((prev) => (prev + 1));
                        }


                    }}
                            style={{
                                opacity: isAllowedToLogin ? 1 : 0.5,
                            }}
                            className="">

                        Fortfahren als {user?.name}


                    </Button>

                </div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Button outline   onClick={() => handleClick("/settings")}
                            className=" ">

                        Einstellungen ändern


                    </Button>
                    <Text style={{marginTop: "6px"}}>Ändere deine Profileinstellungen hier</Text></div>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Button outline onClick={() => {
                        handleClick("/weiterleiten")
                    }}
                            className="">

                        Anwendung auswählen


                    </Button>
                    <Text style={{marginTop: "6px"}} >Wähle die gewünschte JUHUU Anwendung</Text></div>
                <Button outline onClick={async () => {
                    await authProvider.signout()
                }}
                        className="">
                    <ArrowLeftStartOnRectangleIcon />


                    Logout


                </Button>
            </div>
        </div>
    )
        ;
}
