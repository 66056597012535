import AuthContext from "../AuthProvider";

export type ChangeLoginPassword = {
    password: string,
    passwordRepeat: string,
    oldPassword: string
}
/**
 * Change the login password
 * @param data
 */
export default function changeLoginPassword(data: ChangeLoginPassword) {


    return AuthContext.apiRequest(
        `/v1/auth/emailPassword/changePassword`,
        "PATCH",
        {
            password: data.password,
            oldPassword: data.oldPassword
        },
        "Fehler beim Ändern des Passworts.",
        true)

}
