import AuthContext from "../AuthProvider";

/**
 * Send a Verification email to user
 */
export default function verifyEmailAdress() {

    return AuthContext.apiRequest(
        `/v1/users/${AuthContext.sub}/verifyBillingEmail`,
        "GET",
        {},
        "Fehler beim Verifizieren der Email Adresse.",
        true)

}
