import {SubmitHandler, useForm} from "react-hook-form";
import React, {useState} from "react";
import authProvider from "../../../AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {ErrorMessage, Field, Label} from "../../../components/catalyst/fieldset";
import {Input} from "../../../components/catalyst/input";
import {Text} from "../../../components/catalyst/text";
import {Button} from "../../../components/catalyst/button";

export default function Login() {
    const {
        register,
        handleSubmit,


        formState: { errors, isSubmitting },
    } = useForm<{email: string, password: string}>()



   const [errorMsg, setErrorMsg] = useState<null | {err: string}>(null);





    const onSubmit: SubmitHandler<{email: string, password: string}> = async (data) =>
    {

        try {
            const result = await fetch(process.env.REACT_APP_JUHUU_API_ENDPOINT + "/v1/auth/emailpassword/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: data.email,
                    password: data.password,
                })
            })

            const json = await result.json();
            if (json.accessToken && json.refreshToken) {
                await authProvider.signin(json.accessToken, json.refreshToken);
                window.location.reload()
            } else {
                setErrorMsg({
                    err: "Es konnte kein Benutzer gefunden werden oder das Passwort ist falsch!",
                })

            }


        } catch (e) {
            setErrorMsg({
                err: "Etwas ist schief gegangen bitte probiere es erneut!",
            })
        }

    }
    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };


    return <form onSubmit={handleSubmit(onSubmit)} className="space-y-1 w-full" >
        {errorMsg?.err ? (<div
            className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 "
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                {errorMsg.err}
            </div>
        </div>) : null}
        <div>
            <Field>
                <Label>E-Mail Adresse</Label>
                <Input  {...register("email", {required: "Es muss eine E-Mail Adresse angegeben werden"})}
                        id="email"
                        type="email"
                        autoComplete="email"
                        disabled={isSubmitting}
                        invalid={!!errors.email}
                        required />
                {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

            </Field>


        </div>

        <div>

            <Field>
                <Label>Passwort</Label>
                <Input    {...register("password", { required: true })}
                          id="password"
                          disabled={isSubmitting}
                          type="password"
                          autoComplete="current-password"
                          required />
            </Field>


        </div>

        <Text className={"text-right mt-0"}>
              <strong onClick={() => handleClick("/reset-password")}
                    className="cursor-pointer  ">
                        Passwort vergessen?
                    </strong>
        </Text>

        <div className={"flex w-full justify-start mt-0"}>
            <Button
                className="w-full"
                type="submit"
                disabled={isSubmitting}

            >
                Anmelden
            </Button>
        </div>
    </form>
}
