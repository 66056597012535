import React from "react";
import {useLocation, useNavigate, useRouteLoaderData} from "react-router-dom";
import SettingsWrapper from "../components/settings/SettingsWrapper";
import SettingsPanel from "../components/settings/SettingsPanel";
import ChangeName from "../components/settings/ChangeName";

import ChangeBillingEMail from "../components/settings/ChangeBillingEMail";
import ChangeLoginEMail from "../components/settings/ChangeLoginEMail";
import ChangeBillingAdress from "../components/settings/ChangeBillingAdress";
import ExportAccount from "../components/settings/ExportAccount";
import DeleteAccount from "../components/settings/DeleteAccount";
import ChangeVAT from "../components/settings/ChangeVAT";
import UpgradeToManagementUser from "../components/settings/upgradeToManagementUser";
import VerifyEmail from "../components/verifyEmail";
import VerifySettings from "../components/verifySettings";
import {RootLoaderType} from "../types";
import ChangeLoginPasswordPage from "../components/settings/ChangeLoginPassword";
import {Heading} from "../components/catalyst/heading";
import {Button} from "../components/catalyst/button";
import {ArrowUturnLeftIcon} from "@heroicons/react/16/solid";
import {Divider} from "../components/catalyst/divider";


export default function SettingsPage() {

    const data = useRouteLoaderData("root") as RootLoaderType;


    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    };


    return (
        <div
            className={"grid h-full w-full shadow-md rounded-xl border-2 overflow-hidden place-items-start justify-items-center p-6 py-8 sm:p-8 lg:p-12"}>
            <VerifySettings/>
            <VerifyEmail needsVerification={((!data?.user?.billingEmailVerified) && !!data?.user?.billingEmail)}/>
            <div className="w-full flex gap-5 flex-col">

                <Heading className={"text-center"}>Angemeldet als {data?.user?.name}</Heading>

                <div className="mx-auto w-full  ">


                    <Heading>Settings</Heading>

                    <SettingsWrapper>
                        <SettingsPanel title={"Name ändern"}>
                            <ChangeName/>
                        </SettingsPanel>
                        <SettingsPanel title={"Login E-Mail Adresse ändern"}>
                            <ChangeLoginEMail/>
                        </SettingsPanel>
                        <SettingsPanel title={"Passwort ändern"}>
                            <ChangeLoginPasswordPage/>
                        </SettingsPanel>

                        <SettingsPanel title={"Rechnungs E-Mail Adresse ändern"}>
                            <ChangeBillingEMail/>
                        </SettingsPanel>
                        <SettingsPanel title={"Rechnungsadresse ändern"}>
                            <ChangeBillingAdress/>
                        </SettingsPanel>

                        {data.user?.type === "standard" ?
                            <SettingsPanel text={"Wenn du auf den Button klickst, wird dein Konto zu einem Management-Nutzerkonto hochgestuft. Diese Aktion kann nur einmal durchgeführt werden und ist nicht umkehrbar."} title={"Upgrade auf Management User durchführen"}>
                                <UpgradeToManagementUser/>
                            </SettingsPanel>
                            : null}
                        {data.user?.type === "management"  ? <SettingsPanel title={"VAT Nummer ändern"}>
                            <ChangeVAT/>
                        </SettingsPanel> : null}
                        <SettingsPanel text={"Wenn du auf den Button klickst, erhältst du eine E-Mail mit allen deinen persönlichen Daten."} title={"Persönliche Daten anfordern"}>
                            <ExportAccount/>
                        </SettingsPanel>
                        <SettingsPanel text={"Wenn du auf den Button klickst, wird dein Account dauerhaft gelöscht. Alle deine Daten werden unwiderruflich entfernt."} title={"Account löschen"}>
                            <DeleteAccount/>
                        </SettingsPanel>
                    </SettingsWrapper>
                    <Divider className="my-10 mt-6"/>
                </div>

                <Button outline onClick={() => handleClick("/auth")}
                        className="">
                    <ArrowUturnLeftIcon/> Zurück


                </Button>

            </div>
        </div>
    );
}
