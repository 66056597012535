import React from "react";
import {Heading} from "../../../components/catalyst/heading";
import {Text} from "../../../components/catalyst/text";
import {Button} from "../../../components/catalyst/button";

export default function EmailconfirmedPage() {




    return (
        <>

            <Heading  >E-Mail Bestätigt!</Heading>
            <Text  >Danke für die Bestätigung deiner E-Mail-Adresse. Du kannst
                diese Seite jetzt schließen.</Text>
            <Button className={"w-full mt-10" } href={"/"} outline >
                zurück zur Startseite
            </Button>


        </>
    );
}
