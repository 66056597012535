import React from "react";
import {Heading} from "../../components/catalyst/heading";
import {Text} from "../../components/catalyst/text";
import {Button} from "../../components/catalyst/button";

export default function ErrorIntentPage() {




    return (
        <>

            <Heading  >Es ist ein Fehler aufgetreten</Heading>
            <Text  >Es ist ein Fehler aufgetreten, bitte führe die Aktion erneut aus.</Text>
            <Button className={"w-full mt-10" } href={"/"} outline >
                zurück zur Startseite
            </Button>


        </>
    );
}
