import React, {useMemo, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useRevalidator} from "react-router-dom";
import changeLoginPassword, {ChangeLoginPassword} from "../../actions/changeLoginPassword";
import authProvider from "../../AuthProvider";
import {Button} from "../catalyst/button";
import {ErrorMessage, Field, Label} from "../catalyst/fieldset";
import {Input} from "../catalyst/input";


export default function ChangeLoginPasswordPage() {
    const {
        register,
        handleSubmit,
        watch,
        formState: {errors,},
    } = useForm<ChangeLoginPassword>({
        defaultValues:{
            password: "",
            passwordRepeat: "",
            oldPassword:"",
        }
    })




    const [msg, setMsg] = useState<{ msg: string, err: boolean } | null>(null);


    const revalidator = useRevalidator();

    const password = watch("password")

    const [PasswordKriterien,AllValid, ValidateObject] = useMemo(() => {


        const List = [
            {
                txt: "Das Passwort muss mind. 8 Zeichen haben (max. 64)",
                valid: (pw: string) => {
                    return pw.length >= 8 && pw.length <= 64
                }
            },
            {
                txt: "Groß- und Kleinbuchstaben erforderlich",
                valid: (pw: string) => {
                    return /(?=.*[a-z])(?=.*[A-Z])/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            },
            {
                txt: "Zahl erforderlich",
                valid: (pw: string) => {
                    return /(?=.*\d)/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            },
            {
                txt: "Sonderzeichen erforderlich",
                valid: (pw: string) => {
                    return /(?=.*\W)/.test(pw)
                } // Überprüft, ob das Passwort Groß- und Kleinbuchstaben enthält
            }
        ];

        return [List.map((el) => {
            return {
                txt: el.txt,
                valid: el.valid(password || "")
            }
        }),!List.reduce((prev, cur) => {
            return prev && cur.valid(password || "");

        }, true), {
            laenge: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[0].valid(pw) || List[0].txt;
            },
            grossklein: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[1].valid(pw) || List[1].txt;
            },
            zahl: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[2].valid(pw) || List[2].txt;
            },
            sonderzeichen: (pw: string) => {
                if(!pw){
                    return "Bitte gib ein Passwort ein"
                }
                return List[3].valid(pw) || List[3].txt;
            }
        }]

    }, [password])

    const onSubmit: SubmitHandler<ChangeLoginPassword> = async (data) => {



        const result = await changeLoginPassword(data)

        if (typeof result === 'boolean') {
            if (result) {
                //console.log("Passwort wurde geändert")
                setMsg({msg: "Passwort wurde geändert", err: false})
                await authProvider.getUserDoc()
                revalidator.revalidate();
            } else {
                setMsg({msg: "Es ist ein Fehler aufgetreten!", err: true})
            }
        } else {
            setMsg({msg: result.err, err: true})
        }

    }

    return <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
        {msg ? (<div
            data-variant={msg.err ? "error" : "success"}
            className="flex items-center p-4 mb-4 text-sm  border rounded-lg data-[variant=success]:text-green-800 data-[variant=success]:border-green-300 data-[variant=success]:bg-green-50  data-[variant=error]:text-red-800 data-[variant=error]:border-red-300 data-[variant=error]:bg-red-50 "
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                {msg.msg}
            </div>
        </div>) : null}


        <Field>
            <Label>Aktuelles Passwort<sup className={"text-red-600"}>*</sup></Label>
            <Input type="password"
                   {...register("oldPassword", {required: "Bitte gib dein aktuelles Passwort ein!"})}
                   placeholder={""}/>
            {errors.oldPassword && <ErrorMessage>{errors.oldPassword.message}</ErrorMessage>}
        </Field>
        <Field>
            <Label>Neues Passwort<sup className={"text-red-600"}>*</sup></Label>
            <Input type="password"
                   {...register("password", {
                       required: true,
                       validate: ValidateObject
                   })}
                   placeholder={""}/>
            {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
        </Field>
        <ul className="divide-y divide-gray-100">
            {AllValid && PasswordKriterien.map((pk, index) => (
                <li key={index} className="flex justify-between gap-x-1 py-1">
                    <div className="flex min-w-0 gap-x-4">
                        {pk.valid ? "✅" : "❌"}
                        <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{pk.txt}</p>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
        <Field>
            <Label>Passwort wiederholen<sup className={"text-red-600"}>*</sup></Label>
            <Input  type="password"
                    {...register("passwordRepeat", {
                        required: true,
                        validate: (value, formValues) => {
                            return value === formValues.password || "Die Passwörter stimmen nicht überein!"
                        }
                    })}
                     placeholder={""}/>
            {errors.passwordRepeat && <ErrorMessage>{errors.passwordRepeat.message}</ErrorMessage>}
        </Field>

        <Button type={"submit"} color={"emerald"}
        > Speichern
        </Button>
    </form>
}
