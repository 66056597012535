import React from "react";
import {Heading} from "../../../components/catalyst/heading";
import {Text} from "../../../components/catalyst/text";
import {Button} from "../../../components/catalyst/button";

export default function ReesetPasswordRejectedPage() {




    return (
        <>

            <Heading  >Passwort zurücksetzen wurde abgebrochen!</Heading>
            <Text  >Der Vorgang wurde abgebrochen und dein Passwort wurde nicht zurückgesetzt. Du kannst die Seite jetzt schließen!</Text>
            <Button className={"w-full mt-10" } href={"/"} outline >
                zurück zur Startseite
            </Button>


        </>
    );
}
