import React from "react";
import {Heading} from "../../../components/catalyst/heading";
import {Text} from "../../../components/catalyst/text";
import {Button} from "../../../components/catalyst/button";

export default function ManagementUserRejectedPage() {




    return (
        <>

            <Heading  >Management User Upgrade abgelehnt!</Heading>
            <Text  >Du hast das Upgrade auf einen Managment User abgelehnt.  Du kannst diese Seite nun schließen.</Text>
            <Button className={"w-full mt-10" } href={"/"} outline >
                zurück zur Startseite
            </Button>


        </>
    );
}
