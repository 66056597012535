import React, {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {useRevalidator, useRouteLoaderData} from "react-router-dom";
import updateUserAccount, {ChangeBillingAddress} from "../../actions/updateUserAccount";
import authProvider from "../../AuthProvider";
import {CountryCodeArray, RootLoaderType} from "../../types";
import {Button} from "../catalyst/button";
import {ErrorMessage, Field, Label} from "../catalyst/fieldset";
import {Input} from "../catalyst/input";
import {Select} from "../catalyst/select";


export default function ChangeBillingAdress() {

    const {user} = useRouteLoaderData("root") as RootLoaderType;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ChangeBillingAddress["data"]>(
        {
            defaultValues: user?.billingAddress,
        }
    )





    const [msg, setMsg] = useState<{ msg: string, err: boolean } | null>(null);


    const revalidator = useRevalidator();

    const onSubmit: SubmitHandler<ChangeBillingAddress["data"]> = async (data) =>
    {

        //console.log(data)



        const result = await updateUserAccount({
            type: "changeBillingAddress",
            data:data
        })

        if(typeof result === 'boolean'){
            if(result){
                //console.log("Adresse wurde geändert")
                setMsg({msg:"Adresse wurde geändert", err:false})
                 await  authProvider.getUserDoc()
                revalidator.revalidate();
            } else {
                setMsg({msg:"Es ist ein Fehler aufgetreten!", err:true})
            }
        } else {
            setMsg({msg:result.err, err:true})
        }

    }

    return <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
        {msg ? (<div
            data-variant={msg.err ? "error" : "success"}
            className="flex items-center p-4 mb-4 text-sm  border rounded-lg data-[variant=success]:text-green-800 data-[variant=success]:border-green-300 data-[variant=success]:bg-green-50  data-[variant=error]:text-red-800 data-[variant=error]:border-red-300 data-[variant=error]:bg-red-50 "
            role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 fill="currentColor" viewBox="0 0 20 20">
                <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div>
                {msg.msg}
            </div>
        </div>) : null}



        <Field>
            <Label>Adresszeile 1<sup className={"text-red-600"}>*</sup></Label>
            <Input   type="text"
                     {...register("line1", {required: "Es muss eine Straße und Hausnummer eingegeben Werden"})}
                       placeholder={user?.billingAddress?.line1 || "Straße, Hausnummer"}/>
            {errors.line1 && <ErrorMessage>{errors.line1.message}</ErrorMessage>}
        </Field>


        <Field>
            <Label>Adresszeile 2</Label>
            <Input type="text"
                   {...register("line2")}
                   className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                   placeholder={user?.billingAddress?.line2 || "..."}/>
            {errors.line2 && <ErrorMessage>{errors.line2.message}</ErrorMessage>}
        </Field>
        <div className="flex gap-3">

            <Field>
                <Label>PLZ<sup className={"text-red-600"}>*</sup></Label>
                <Input  type="text"
                        {...register("postalCode", {required: "Es muss eine Postleitzahl eingegeben Werden"})}
                         placeholder={user?.billingAddress?.postalCode || "PLZ"}/>
                {errors.postalCode && <ErrorMessage>{errors.postalCode.message}</ErrorMessage>}
            </Field>


            <Field>
                <Label>Stadt<sup className={"text-red-600"}>*</sup></Label>
                <Input   type="text"
                         {...register("city", {required: "Es muss eine Stadt eingegeben Werden"})}
                         className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                         placeholder={user?.billingAddress?.city || "Stadt"} />
                {errors.city && <ErrorMessage>{errors.city.message}</ErrorMessage>}
            </Field>
        </div>
        <Field>
            <Label>Land<sup className={"text-red-600"}>*</sup></Label>
            <Select   {...register("country", {required: "Es muss ein Land ausgewählt werden"})}>
                <option value={""}>Land auswählen</option>
                {
                    CountryCodeArray.map((countryCode, index) => {

                        return <option key={`country_${index}`} value={countryCode.code}>{countryCode.name}</option>
                    })
                }
            </Select>
            {errors.country && <ErrorMessage>{errors.country.message}</ErrorMessage>}
        </Field>




        <Button type={"submit"} color={"emerald"}
        >  Speichern
        </Button>
    </form>
}
