import React, {useCallback, useMemo} from "react";
import {useLocation, useNavigate, useRouteLoaderData} from "react-router-dom";
import {RootLoaderType} from "../../types";


const VerifySettings: React.FC = () => {
    const {user} = useRouteLoaderData("root") as RootLoaderType;

    const location = useLocation();
    const navigate = useNavigate();
    const handleClick = useCallback( (link: string) => {
        // Get the current search params
        const searchParams = new URLSearchParams(location.search);
        navigate(`${link}?${searchParams.toString()}`);
    },[location, navigate]) ;

    const Alerts = useMemo(()=>{

        const SuperAlerts = [];

        if(!user?.name){
            SuperAlerts.push(<>Du musst einen <b>Namen</b> festlegen um die Anmeldung fortsetzten zu können! -&gt; <span onClick={() => handleClick("/settings")}>Link</span> </>)
        }

        if(!user?.billingEmail){
            SuperAlerts.push(<>Du musst einen <b>Billing E-Mail</b> festlegen um die Anmeldung fortsetzten zu können! -&gt; <span onClick={() => handleClick("/settings")}>Link</span></>)
        }


        return SuperAlerts;

    },[user, handleClick])




    return (
        <div className={" mx-auto"}>
            {Alerts.map((item, i) => {
                return <div
                    key={`alert_${i}`}
                    className="mx-4 f flex   gap-3 items-center p-4 mb-4 text-xs border rounded-lg text-red-800 border-red-300 bg-red-50"
                    role="alert"
                >
                    <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="currentColor" viewBox="0 0 20 20">
                        <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                    </svg>

                    <div className={"text-[1rem]  "}>
                        {item}
                    </div>


                </div>
            })}


        </div>
    );
};

export default VerifySettings;
